// http://wahabali.com/work/vation/images/slider/img-01.jpg
.hero-area {
  background-image: url('../images/slider/hero-area.jpg');
  background-size: cover;
  height: 100vh;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  &:before {
    content:'';
    background: rgba(0, 0, 0, 0.63);
    position: absolute;
    top:0;
    right:0;
    bottom:0;
    left:0;
  }
  .block {
    text-align: center;
    z-index: 99;
    .video-button {
      background:$white;
      color:$primary-color;
      display: inline-block;
      height:60px;
      width: 60px;
      border-radius:50px;
      i {
        line-height: 60px;
        font-size:20px;
        margin-left:3px;
      }
    }
    h1 {
      font-size: 90px;
      color:$white;
      text-transform: capitalize;
      font-weight: 700;
      margin-bottom: 20px;
      @include mobile {
        font-size:60px;
      }
    }
    p {
      color: $light;
      font-size: 20px;
      width:70%;
      margin:0 auto;
    }
    .btn-transparent {
      margin-top: 40px;
      border-color:$white;
      padding:14px 50px;
      font-size:18px;
      font-size:16px;
      font-weight: bold;
      letter-spacing: 1px;
    }
  }
}

.hero-area-video {
  height: 100vh;
  position: inherit!important;
  display: flex;
  justify-content: center;
  align-items: center;
  .block {
    color: $light;
    text-align: center;
    h1 {
      font-size: 60px;
      font-weight: 700;
      margin-bottom: 20px;
    }
    p {
      color: $light;
      text-transform: uppercase;
      font-size: 12px;
      letter-spacing: 3px;
      margin-bottom: 20px
    }
    .btn-main {
      margin-top: 20px;
    }
  } 
}

.dark-bg {
  background: rgba(0, 0, 0, 0.7);
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: -1;
}